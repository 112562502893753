<template>
  <div class="uk-section uk-section-small">
    <div class="uk-container uk-container-expand">
    </div>
  </div>
</template>

<script>
export default {
  middleware: 'auth',
  components: {
    //
  },
  mounted() { },
}
</script>
